import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Divider`}</inlineCode>{` Sebuah unsur visual yang biasannya merupakan garis tipis dan kecil yang digunakan untuk membagi konten dalam grup.`}</p>
    </PageDescription>
    <h2>{`Use Case`}</h2>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Horizontal" titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "518px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAACd0lEQVQ4y61Uu47TQBTNbyBo+AiEqOgQoqKjoadAQvwEEiUIkIAOOihYsRJilz9YQDSBLMnGOCSx4/UjDz/HY48PcyeZKMHeBQmudOLJeObMvefccSvJOP4nWvQTp/k/E2mO1nKQqwlClLD1+DTQOg1NSFhluDwlleAlkOXlHzOidYUAygpbFa5LzlgBIQQs20YUxaAQokJVVRAEsXquYNsTmKYJwzAkeSUzzeuEtHk2m4ExBh001xTz+Rye58F1XXCZ6lpDTZgyjizLakTT6RS9Xk+BNuvMNyNjvE6Y5QWSJEEoy03SDEWxlICedFCapuCcqzlCWZYKNKa9NcKcC6mLBWtgwDKPpI4h/iaokkZCJm1LIoa9L0O8a9sIFyl830MQBBLT1TOQh9pwHAee7yOWFTWWHMZkQoWv3QBnru3j9g0To8MA3f4h2u02Op1vGI/HCMNQHuIrYjJFa17LMJSWU1MdLGKcu3uAJ2cdjH/MYTsjDAY/lban1Az2O2Ek/5SshAWOe5/72L3fwbEXYxSkGLgphgHHLBFbumlQsCYNE6kDNtpBLsciStaI4kSVSNlqpzUaTaE+pHj4+DmePnup9DL6PfSPuvBdB4E0iBqZtFxmp3u1wWUyhV6ZgyEuXLyMK1evY3LsYzjxYQylo4sM8npvhb6OVZOGurHp5c7b93j1+g1IMXfB4UclxtMc86RQ2YiGq9hccsZPsFEoIqiMlpoVpcDIjdG3QrgzmT0/obFJN9KImteyLNVvq85Qkqzvd5jj0p0POH9zF7cefFRzi5jVvzaM5cpFQhRF6v5uullKEHsq177YN/Bo5zv2Plnqnf58/QIZbPvGg+opsQAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/b2b1f627c386d13bd2ed5f9d4e9e0d48/d7f6f/example.png",
              "srcSet": ["/static/b2b1f627c386d13bd2ed5f9d4e9e0d48/7fc1e/example.png 288w", "/static/b2b1f627c386d13bd2ed5f9d4e9e0d48/d7f6f/example.png 518w"],
              "sizes": "(max-width: 518px) 100vw, 518px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      